<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
  <v-card-text>
      <v-card-title class="mt-0 pt-0">
        <span class="me-3">({{ productProspects.length }}) Prospects</span>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="productProspects"
        item-key="id"
        class="table-rounded"
        :items-per-page="10"
        disable-sort
        :search="search"
      >
        <!-- name -->
        <template #[`item.username`]="{item}">
          <div class="d-flex flex-column" style="cursor: pointer">
            <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.username }}</span>
            <small>Campaign: {{ item.name }} </small>
          </div>
        </template>
        <template #[`item.saved_amount`]="{item}">
          {{ item.saved_amount }} F CFA
        </template>
        <!-- status -->
        <template #[`item.status`]="{item}">
          <v-chip
            small
            :color="statusColor[item.status]"
            class="font-weight-medium"
          >
            {{ item.status }}
          </v-chip>
        </template>

        <template #[`item.phone_number`]="{item}">
          <a 
              :href="'https://api.whatsapp.com/send?phone='+item.phone_number+'/&text=Warm greetings *'+item.username+'* 😃, we at PettyCash SAS hope you are doing great 💫 today. %0a We noticed your campaign *'+item.campaign_name+'* is related to this item in our marketplace. %0a *'+product_detail.name+'*, %0a *'+item.no_loans+'*, %0a *'+item.amount+' Current Savings*, %0a *'+item.amount_owed_so_far+' Debts.*'" 
              target="_blank"
              style="text-decoration: none"
          >
            <v-btn class="success" elevation="6" icon small>
              <font-awesome-icon icon="fa-solid fa-comments"/>
            </v-btn>
          </a>
        </template>

        <template #[`item.get_date_str`]="{item}">
          {{ item.get_date_str }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import Spinner from "@/components/Spinner.vue";
import axios from 'axios'

export default {
  components: {
    Spinner,
  },

  data() {
    const statusColor = {
      /* eslint-disable key-spacing */
      Ongoing: 'primary',
      Completed: 'success',
      Interrupted: 'error',
      Inactivated: 'error',
      /* eslint-enable key-spacing */
    }
    const client_id = this.$route.params.client_id
    return {
      client_id,
      headers: [
        { text: 'Client', value: 'username' },
        { text: 'Description', value: 'description' },
        { text: 'Savings', value: 'saved_amount' },
        { text: 'Status', value: 'status' },
        { text: 'Chat', value: 'phone_number' },
        { text: 'Date/Time', value: 'get_date_str' },
      ],

      statusColor,
      search: '',
      loading: false,
      product_detail: '',
      productProspects: [],
    }
  },

  mounted(){
    this.getProductDetails()
    this.getProductProspects()
    document.title = "PettyCash: Manager | Product Prospects"
  },

  methods:{
    async getProductDetails(){
      this.loading = true
      const product_id = this.$route.params.id

      await axios
        .get('/api/v1/manager/market_place/get/product/detail/'+product_id+'/')
        .then(response => {
          console.log(response.data)

          this.product_detail = response.data["product_info"]
        })
        .catch(error => {
            if (error.response){
                for (const property in error.response.data){
                    this.$store.commit('setSnackBarMessage', error.response.data[property][0])
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                }
            }else{
                this.$store.commit('setSnackBarMessage', "An error occured please try again later.")
                this.$store.commit('setSnackBarColor', "red darken-4")
                this.$store.commit('activateSnackBar', true)
            }
        })

      this.loading = false
    },

    async getProductProspects(){
      this.loading = true
      const product_id = this.$route.params.id

      await axios
        .get('/api/v1/manager/market_place/get/product/prospects/'+product_id+'/')
        .then(response => {
          console.log('prospects', response.data)
          this.productProspects = response.data['product_prospects']
          // this.savings_total = response.data['total_saved']
        })
        .catch(error => {
              if (error.response){
                  for (const property in error.response.data){
                      this.$store.commit('setSnackBarMessage', error.response.data[property][0])
                      this.$store.commit('setSnackBarColor', "red darken-4")
                      this.$store.commit('activateSnackBar', true)
                  }
              }else{
                  this.$store.commit('setSnackBarMessage', "An error occured while logging into your account")
                  this.$store.commit('setSnackBarColor', "red darken-4")
                  this.$store.commit('activateSnackBar', true)
              }
          })
      this.loading = false
    },
  },

}
</script>
