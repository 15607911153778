<template>
  <v-card id="account-setting-card">
    <!-- tabs -->
    <v-tabs
      v-model="tab"
      show-arrows
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.icon"
      >
        <v-icon
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <product></product>
      </v-tab-item>

      <v-tab-item>
        <product-info></product-info>
      </v-tab-item>

      <v-tab-item>
        <product-reviews></product-reviews>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { mdiAccountGroupOutline, mdiComment, mdiInformationOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'

// demos
import Product from './Product.vue'
import ProductInfo from './ProductInfo.vue'
import ProductReviews from './ProductReviews'
import axios from 'axios'


export default {
  components: {
    Product,
    ProductInfo,
    ProductReviews,
  },
  setup() {
    const tab = ref('')

    // tabs
    const tabs = [
      { title: 'Product Details', icon: mdiInformationOutline },
      { title: 'Prospects', icon: mdiAccountGroupOutline },
      { title: 'Reviews', icon: mdiComment },
    ]

    return {
      tab,
      tabs,
      icons: {
        mdiInformationOutline,
        mdiAccountGroupOutline,
        mdiComment,
      },
    }
  },

  data() {
    return {}
  },
  
  mounted(){
    document.title = "PettyCash: Manager | Product"
  },

}
</script>
