<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
  <v-card-text>
      <v-card-title class="mt-0 pt-0">
        <span class="me-3">({{ productReviews.length }}) Reviews</span>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <!-- <h3 class="ml-3">Total Cash Saved: {{ savings_total }} F CFA  </h3> -->
      <v-data-table
        :headers="headers"
        :items="productReviews"
        item-key="id"
        class="table-rounded"
        :items-per-page="10"
        disable-sort
        :search="search"
      >
        <!-- name -->
        <template #[`item.product_review.reviewer_name`]="{item}">
          <div 
            style="cursor: pointer" 
            @click.stop="setDialog=true" 
            @click="changeReview(item.product_review.id)" 
            class="d-flex flex-column">
            <span class="d-block font-weight-semibold text--primary text-truncate">
              {{ item.product_review.reviewer_name }}
            </span>
            <small>Product Rating: <b>{{ item.product_review.rating }}</b></small>
          </div>
        </template>

        <template #[`item.review_reply`]="{item}">
          <div v-if="item.review_reply.length > 0" >
            <div v-for="reply in item.review_reply" :key="reply.id">
              <b>By: {{ reply.replier_username }}</b>
              {{ reply.reply }}
            </div>          
          </div>
          <div v-else>
            None
          </div>          
        </template>

        <template #[`item.product_review.get_date_str`]="{item}">
          {{ item.product_review.get_date_str }}
        </template>
      </v-data-table>
    </v-card-text>
    <v-dialog width="500px" v-model="setDialog"> 
        <v-card> 
          <v-card-title class="dark--text">{{ dialogTitle }}</v-card-title> 
          <v-card-text class="black--text pt-2 mb-0 pb-0">
            <span v-html="ReviewDetails"></span>
              <v-textarea
                v-model="review_reply"
                outlined class="mt-2"
                rows="2"
                ref="review_reply" id="review_reply"
                label="Reply this review here"
              ></v-textarea>
          </v-card-text> 
          <v-divider></v-divider> 
          <v-card-actions> 
            <v-spacer></v-spacer> 
            <v-btn small color="success" text @click="setDialog = false" > Close </v-btn> 
            <v-btn small color="primary" @click="replyReview()" > Reply </v-btn> 
          </v-card-actions> 
        </v-card> 
    </v-dialog> 
  </v-card>
</template>

<script>
import Spinner from "@/components/Spinner.vue";
import axios from 'axios'

export default {
  components: {
    Spinner,
  },

  data() {
    const client_id = this.$route.params.client_id
    return {
      setDialog: false,
      dialogTitle: "",
      ReviewDetails: "",
      review_id: "",
      review_reply: '',
      client_id,
      headers: [
        { text: 'Reviewer', value: 'product_review.reviewer_name' },
        { text: 'Review', value: 'product_review.review' },
        { text: 'Ratings', value: 'product_review.rating' },
        { text: 'Likes', value: 'product_review.no_likes' },
        { text: 'Dislikes', value: 'product_review.no_dislikes' },
        { text: 'Replies', value: 'review_reply' },
        { text: 'Date/Time', value: 'product_review.get_date_str' },
      ],

      search: '',
      loading: false,
      product_detail: '',
      productReviews: [],
    }
  },

  mounted(){
    this.getProductDetails()
    this.getProductReviews()
    document.title = "PettyCash: Manager | Product Reviews"
  },

  methods:{
    async getProductDetails(){
      this.loading = true
      const product_id = this.$route.params.id

      await axios
        .get('/api/v1/manager/market_place/get/product/detail/'+product_id+'/')
        .then(response => {
          console.log(response.data)

          this.product_detail = response.data["product_info"]
        })
        .catch(error => {
            if (error.response){
                for (const property in error.response.data){
                    this.$store.commit('setSnackBarMessage', error.response.data[property][0])
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                }
            }else{
                this.$store.commit('setSnackBarMessage', "An error occured please try again later.")
                this.$store.commit('setSnackBarColor', "red darken-4")
                this.$store.commit('activateSnackBar', true)
            }
        })

      this.loading = false
    },

    async getProductReviews(){
      this.loading = true
      const product_id = this.$route.params.id

      await axios
        .get('/api/v1/manager/market_place/get/product/reviews/'+product_id+'/')
        .then(response => {
          console.log('reviews', response.data)
          this.productReviews = response.data
        })
        .catch(error => {
          if (error.response){
            for (const property in error.response.data){
              this.$store.commit('setSnackBarMessage', error.response.data[property][0])
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
            }
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured while logging into your account")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
          })
      this.loading = false
    },

    async replyReview(){
      let formData = new FormData()
      formData.append('review_id', this.review_id)
      formData.append('review_reply', this.ReviewDetails)

      axios
        .post('/api/v1/manager/market_place/add/review/reply', formData, {headers: {'Content-Type': 'multipart/form-data'}})
        .then(response => {
          this.$store.commit('setSnackBarMessage', response.data[0].text)
          this.$store.commit('setSnackBarColor', "primary darken-4")
          this.$store.commit('activateSnackBar', true)
          this.$store.commit('setSnackBarTimeOut', 4000)  // set time out for snack bar to 6seconds and remember too reset after 
          this.getProductReviews()
          this.setDialog = false
        })
        .catch(error => {
          this.setDialog = false
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$store.commit('setSnackBarTimeOut', 4000)  // set time out for snack bar to 6seconds and remember too reset after 
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
    },

    changeReview(review_id){
      axios
        .get('/api/v1/manager/market_place/get/product/review/'+review_id+'/')
        .then(response => {
          this.dialogTitle = "Review By: "+response.data["product_review"]["reviewer_name"]
          this.ReviewDetails = response.data["product_review"]["review"]
          this.review_id = response.data["product_review"]["id"]
          
          console.log(response.data)
        })
        .catch(error => {
          this.setDialog = false
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
    },
  },
  

}
</script>

