<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text class="d-flex">
      <v-row>
        <v-col
          md="4"
          cols="12"
          class="text-center"
        >
          <v-carousel height="auto" style="height: 200px"> 
            <v-carousel-item v-for="item in product_videos" :key="'video_'+item.id" style="height: 200px">
              <video controls height="200">
                <source :src="item.get_video" type="video/mp4">
              </video>
            </v-carousel-item> 
            <v-carousel-item contain style="height: 200px; width: 300px" :src="product_detail.get_image"></v-carousel-item> 
            <v-carousel-item contain style="height: 200px; width: 300px" v-for="item in product_images" :key="'image_'+item.id" :src="item.get_image"></v-carousel-item> 
          </v-carousel> 
        </v-col>

        <v-col
            md="8"
            cols="12"
          >
          <v-card class="white pt-2 pb-4"  style="min-height: 220px; border-radius: 10px" >
            <h2 class="dark--text font-weight-bold"><u>{{ product_detail.name }}</u></h2>
            <v-card-text class="pa-1" style="font-size: 12px; ">
              <span class="text-blue subheading" style="line-height: 1.2"><b>Description:</b> 
                <span v-html="product_detail.description" class="text-blue" style="font-size: 12px"> </span>
              </span>
              <v-row class="pl-4">
                <v-col cols='12' class="pa-0 mt-2" >
                  <a 
                    :href="'https://api.whatsapp.com/send?phone='+product_detail.supplier_contact+'/&text=I\'m a PettyCash core team member, and am glad to bring you the following on your product:- %0a *'+product_detail.name+'*'" 
                    target="_blank"
                    style="text-decoration: none"
                  >
                    <v-btn color="success" class="white--text pa-2 ma-1" small><i class="fab fa-whatsapp mr-1"></i> Whatsapp Supplier</v-btn> 
                  </a>
                </v-col>
                <v-col cols='6' class="pa-0" >
                  <span class="dark--text font-weight-bold">Sold At:</span><span> {{product_detail.price}} FCFA</span>
                </v-col>
                <v-col cols='6' class="pa-0" >
                  <span class="dark--text font-weight-bold">Quantity:</span><span> {{product_detail.quantity}}</span>
                </v-col>
                <v-col cols='6' class="pa-0" >
                  <span class="dark--text font-weight-bold ">No Bought:</span><span> {{product_detail.no_bought}}</span>
                </v-col>
                <v-col cols='6' class="pa-0" >
                  <span class="dark--text font-weight-bold ">Category:</span><span> {{product_detail.category}}</span>
                </v-col>
              </v-row>
            </v-card-text>
        </v-card>
        </v-col>

      </v-row>
    </v-card-text>

    <v-card-text>
      <v-form class="multi-col-validation mt-6">
        <v-row>
          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="name"
              ref="name"
              label="Product Name"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="supplier_contact"
              ref="supplier_contact"
              label="Enter Supplier Contact"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="12" class="pb-0 mb-0"><h3>Product Description:</h3></v-col>
          <v-col cols="12" class="mb-8">
            <vue-editor style="height: 210px" ref="description" v-model="description" />
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="price"
              ref="price"
              label="Product Selling Price"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="wholesale_price"
              ref="wholesale_price"
              dense
              label="Product Wholesale Price"
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="quantity"
              ref="quantity"
              type="number"
              outlined
              dense
              label="No Instock"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="category"
              outlined
              dense
              item-text="name"
              item-value="id"
              label="Choose Product Category"
              :items="categoryItems"
            ></v-select>
          </v-col>

          <v-col cols="12" md="6" class="py-0 my-1">
            <v-file-input
              show-size
              v-model="cover_image"
              ref="cover_image"
              truncate-length="18"
              style="cursor: pointer"
              label="Choose Cover Image"
            ></v-file-input>
            <small>{{ product_detail.get_image }}</small>
          </v-col>

          <v-col cols="12" md="6" class="py-0 my-1">
            <v-file-input
              show-size
              v-model="add_image"
              ref="add_image"
              truncate-length="18"
              style="cursor: pointer"
              label="Add New Image"
            ></v-file-input>
          </v-col>

          <v-col cols="12" md="6" class="py-0 my-1">
            <v-file-input
              show-size
              v-model="add_video"
              ref="add_video"
              truncate-length="18"
              style="cursor: pointer"
              label="Add New Video"
            ></v-file-input>
          </v-col>

          <v-col
            cols="12"
          >
            <v-text-field
              v-model="key_words"
              ref="key_words"
              outlined
              dense
              label="Key Words"
            ></v-text-field>
            <small>Enter keywords and seperate them with commas</small>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12"><h3>Select images to delete</h3></v-col>
          <v-col cols="4" sm="3" v-for="(item, i) in product_images" :key="'del_image_'+item.id">
            <v-img
              :src="item.get_image"
              max-width="75px"
              height="75px"
              alt="logo"
              contain
              class="me-0 "
            ></v-img>            
            <v-checkbox :label='" Delete Image "+i' :id="'delete_image_'+item.id">
            </v-checkbox>
          </v-col>

          <v-col cols="12"><h3>Select videos to delete</h3></v-col>
          <v-col cols="4" sm="3" v-for="(item, i) in product_videos" :key="'del_video_'+item.id">
            <video controls height="100">
              <source :src="item.get_video" type="video/mp4">
            </video>
            <v-checkbox :label='"Delete Video "+i' :id="'delete_video_'+item.id">
            </v-checkbox>
          </v-col>

          <v-col cols="12">
            <spinner v-if="loading"></spinner>
            <v-btn v-else
              color="primary"
              class="me-3 mt-4"
              @click="updateProductDetails()"
            >
              Save changes
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Spinner from "@/components/Spinner.vue";
import axios from 'axios'
import { mdiAlertOutline, mdiCloudUploadOutline } from '@mdi/js'
import { VueEditor } from "vue2-editor";

export default {
  components: {
    Spinner,
    VueEditor,
  },
  
  data() {
    const status = ['Active', 'Inactive', 'Pending', 'Closed']

    return {
      status,
      loading: false,
      product_images: [],
      product_detail: [],
      product_videos: [],
      categoryItems: [],

      name: "",
      category: "",
      supplier_contact: "",
      description: "",
      price: "",
      wholesale_price: "",
      cover_image: [],
      add_image: [],
      add_video: [],
      no_bought: "",
      quantity: "",
      key_words: '',

      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
      },
    }
  },

  mounted(){
    this.getProductCategories()
    this.getProductDetails()
    document.title = "PettyCash: Manager | Product"
  },

  methods:{
    getProductCategories(){
      axios
        .get('/api/v1/manager/market_place/get/product/categories/')
        .then(response => {
          console.log(response.data)
          this.categoryItems = response.data
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
    },

    async getProductDetails(){
      this.loading = true
      const product_id = this.$route.params.id

      await axios
        .get('/api/v1/manager/market_place/get/product/detail/'+product_id+'/')
        .then(response => {
          console.log(response.data)

          this.product_detail = response.data["product_info"]
          
          this.name = this.product_detail["name"]
          this.supplier_contact = this.product_detail["supplier_contact"]
          this.description = this.product_detail["description"]
          this.price = this.product_detail["price"]
          this.wholesale_price = this.product_detail["wholesale_price"]
          this.no_bought = this.product_detail["no_bought"]
          this.category = this.product_detail["category_id"]
          this.quantity = this.product_detail["quantity"]
          this.key_words = this.product_detail["key_words"]

          this.product_images = response.data["product_images"]
          this.product_videos = response.data["product_videos"]

        })
        .catch(error => {
            if (error.response){
                for (const property in error.response.data){
                    this.$store.commit('setSnackBarMessage', error.response.data[property][0])
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                }
            }else{
                this.$store.commit('setSnackBarMessage', "An error occured please try again later.")
                this.$store.commit('setSnackBarColor', "red darken-4")
                this.$store.commit('activateSnackBar', true)
            }
        })

      this.loading = false
    },

    async updateProductDetails(){
        if(this.name === ""){
            this.$store.commit('setSnackBarMessage', "Please input product name.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.name.focus()  // this causes a an auto fucos to the element
        }
        else if(!this.cover_image){
          this.$store.commit('setSnackBarMessage', "Please upload product cover image")
          this.$store.commit('setSnackBarColor', "red darken-4")
          this.$store.commit('activateSnackBar', true)
          this.$refs.cover_image.focus()  // this causes a an auto fucos to the element
        }
        else if(!this.supplier_contact){
          this.$store.commit('setSnackBarMessage', "Please input contact which clients will contact for inquires on whatsapp")
          this.$store.commit('setSnackBarColor', "red darken-4")
          this.$store.commit('activateSnackBar', true)
          this.$refs.supplier_contact.focus()  // this causes a an auto fucos to the element
        }
        else if(this.category === ""){
            this.$store.commit('setSnackBarMessage', "Please select product category.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.category.focus()  // this causes a an auto fucos to the element
        }
        else if(!this.quantity || this.quantity === ""){
            this.$store.commit('setSnackBarMessage', "Please input product stock.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.quantity.focus()  // this causes a an auto fucos to the element
        }
        else if(this.wholesale_price === ""){
            this.$store.commit('setSnackBarMessage', "Please input product whole sale price.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.wholesale_price.focus()  // this causes a an auto fucos to the element
        }
        else if(this.price === ""){
            this.$store.commit('setSnackBarMessage', "Please input product price.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.price.focus()  // this causes a an auto fucos to the element
        }
        else if(this.key_words === ""){
            this.$store.commit('setSnackBarMessage', "Please product key words")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.key_words.focus()  // this causes a an auto fucos to the element
        }
        else {
            this.loading = true
            const product_id = this.$route.params.id
  
            let formData = new FormData()
            formData.append('product_id', product_id)
            formData.append('name', this.name)
            formData.append('supplier_contact', this.supplier_contact)
            formData.append('cover_image', this.cover_image)
            formData.append('add_video', this.add_video)
            formData.append('add_image', this.add_image)
            for(const file_key in this.product_images){
              formData.append('delete_image_'+String(this.product_images[file_key].id), document.getElementById("delete_image_"+this.product_images[file_key].id).checked)
            }
            for(const video_key in this.product_videos){
              formData.append('delete_video_'+String(this.product_videos[video_key].id), document.getElementById("delete_video_"+this.product_videos[video_key].id).checked)
            }
            formData.append('category', this.category)
            formData.append('quantity', this.quantity)
            formData.append('key_words', this.key_words)
            formData.append('description', this.description)
            formData.append('price', this.price)
            formData.append('wholesale_price', this.wholesale_price)

            await axios
                .post('/api/v1/manager/market_place/edit/product/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                .then(response => {
                    console.log(response.data)
                    this.$store.commit('setSnackBarMessage', response.data[0].text)
                    this.$store.commit('setSnackBarColor', response.data[0].color)
                    this.$store.commit('activateSnackBar', true)
                    this.$store.commit('setSnackBarTimeOut', 8000)  // set time out for snack bar to 6seconds and remember too reset after 

                    if (response.data[0].category === "success"){
                      var navigation = this.$router
                      setTimeout(function(){
                        navigation.push('/marketplace/products/')
                      }, 3000)
                    }
                })
                .catch(error => {
                    if (error.response){
                        this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                        this.$store.commit('setSnackBarColor', "red darken-4")
                        this.$store.commit('activateSnackBar', true)
                    }else{
                        this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                        this.$store.commit('setSnackBarColor', "red darken-4")
                        this.$store.commit('activateSnackBar', true)
                    }
                })
            
            this.loading = false
        }

    },

  },
}
</script>
