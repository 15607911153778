var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-3 mt-2",attrs:{"flat":""}},[_c('v-card-text',[_c('v-card-title',{staticClass:"mt-0 pt-0"},[_c('span',{staticClass:"me-3"},[_vm._v("("+_vm._s(_vm.productProspects.length)+") Prospects")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"table-rounded",attrs:{"headers":_vm.headers,"items":_vm.productProspects,"item-key":"id","items-per-page":10,"disable-sort":"","search":_vm.search},scopedSlots:_vm._u([{key:"item.username",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column",staticStyle:{"cursor":"pointer"}},[_c('span',{staticClass:"d-block font-weight-semibold text--primary text-truncate"},[_vm._v(_vm._s(item.username))]),_c('small',[_vm._v("Campaign: "+_vm._s(item.name)+" ")])])]}},{key:"item.saved_amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.saved_amount)+" F CFA ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-medium",attrs:{"small":"","color":_vm.statusColor[item.status]}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.phone_number",fn:function(ref){
var item = ref.item;
return [_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":'https://api.whatsapp.com/send?phone='+item.phone_number+'/&text=Warm greetings *'+item.username+'* 😃, we at PettyCash SAS hope you are doing great 💫 today. %0a We noticed your campaign *'+item.campaign_name+'* is related to this item in our marketplace. %0a *'+_vm.product_detail.name+'*, %0a *'+item.no_loans+'*, %0a *'+item.amount+' Current Savings*, %0a *'+item.amount_owed_so_far+' Debts.*',"target":"_blank"}},[_c('v-btn',{staticClass:"success",attrs:{"elevation":"6","icon":"","small":""}},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-comments"}})],1)],1)]}},{key:"item.get_date_str",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.get_date_str)+" ")]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }